$green: #4cd964;
$turquoise: #5ac8fa;
$blue: #007aff;
$light-blue: #7DC8E8;
$purple: #5856d6;
$red: #ff2d55;

.progress-bar3 {
  height: 2px;
  border-radius: 4px;
  background-image:
          linear-gradient(to right,
                  $green, $turquoise, $blue,
                  $light-blue, $purple, $red);
  transition: 0.4s linear;
  transition-property: width, background-color;
}

.progress-infinite .progress-bar3 {
  width: 50%;
  background-image:
          linear-gradient(to right, $green, $turquoise, $blue, $light-blue, $purple, $red);
  animation: colorAnimation 3s infinite;
}

@keyframes colorAnimation {
  0% {
    background-image:
            linear-gradient(to right, $green, $turquoise, $blue, $light-blue, $purple, $red);
  }
  20% {
    background-image:
            linear-gradient(to right, $turquoise, $blue, $light-blue, $purple, $red, $green);
  }
  40% {
    background-image:
            linear-gradient(to right, $blue, $light-blue, $purple, $red, $green, $turquoise);
  }
  60% {
    background-image:
            linear-gradient(to right, $light-blue, $purple, $red, $green, $turquoise, $blue);
  }
  100% {
    background-image:
            linear-gradient(to right, $purple, $red, $green, $turquoise, $blue, $light-blue);
  }
}

.wrapper {
  /*This part is important for centering*/
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.wrapper-progress {
  /*This part is important for centering*/
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.typing-demo {
  width: 20ch;
  animation: typing 2s steps(15), blink .5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  font-family: monospace;
  font-size: 1em;
}

@keyframes typing {
  from {
    width: 0
  }
}

@keyframes blink {
  50% {
    border-color: transparent
  }
}