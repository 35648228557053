@use "Header1";
@use "welcomeArea1";
@use "trust-section1";
@use "About1";
@use "demo-video1";
@use "ServiceArea1";
@use "roadmap1";
@use "features1";
@use "subscribe1";
@use "FAQ1";
@use "TeamArea1";
@use "partners1";
@use "BlogArea1";
@use "Contact1";
@use "roadmap2";
@use "NftBlock";
@use "progressbar";

.section-heading h2 {
  color: #fff;
}

.bg-daimond{
  background-color: #62e2ff!important;
}