.HomeDemo1 .containerNft {
    width: 90%;
    margin: auto;
}

.HomeDemo1 .cards-containerNft {
    display: flex;
    border-radius: 6px;
    color: #333;
    padding: 1rem;
    box-shadow: 0 0 1rem #000 inset;
    overflow-x: auto;
    position: relative;

&::-webkit-scrollbar {
     display: none;
 }
}

.HomeDemo1 .cardNft {
    flex: 1 0 250px;
    box-shadow: 0 1px 1rem -4px #000;
    background: #fff;
    margin: 1rem;
    overflow: hidden;
    border-radius: 6px;
    cursor: pointer;
    transition: all 250ms ease-in-out;

    img {
    width: 250px;
    height: 250px;
    object-fit: center;
}

&:hover {
     box-shadow: 0 4px 1rem -4px #000;
     transform: translateY(-3px);
 }
}

.HomeDemo1 .card-contentNft {
    padding: 1rem;
}

@media screen and (max-width: 625px) {
    .HomeDemo1 .containerNft {
        width: 100%
    }

    .HomeDemo1 .cards-containerNft {
        padding: .5rem;
    }

    .HomeDemo1 .cardNft {
        margin: .5rem;
    }
}