/* ##### Partners Area ##### */
.HomeDemo1 .partners{
    padding: 0 0 70px
}
.HomeDemo1 .partner-box{
    border: 1px solid #9469BF;
    border-radius: 10px;
    padding: 20px 10px;
    text-align: center;
    vertical-align: middle;
    background-color: #0d003b;
    margin-bottom: 30px;
    transition: all .3s ease-in-out;
}
.HomeDemo1 .partner-box:hover{
    background: #9469BF;
    cursor: pointer;
}