.HomeDemo1 .trust-section{
    position: relative;
    padding: 100px 0 60px
}

.HomeDemo1 .trust-section .card {
    transition: .3s;
}

.HomeDemo1 .trust-section .card:hover {
    -webkit-box-shadow: 5px 5px 13px 6px rgba(0, 0, 0, 0.25);
    box-shadow: 5px 5px 13px 6px rgba(0, 0, 0, 0.25);
}